<template>
  <div>
    <div class="section section-1">
      <div class="content">
        <div class="text">
          <div class="text-1 color-orange">广阔市场，大有作为</div>
          <div class="text-2 color-black">了解如何成为店教授合作伙伴</div>
          <div>
            <a class="apply" href="https://www.yunlankeji.com/" target="_blank"
              >立即申请 / 登录</a
            >
          </div>
          <div class="text-3 color-black">渠道合作专线 400-066-0526</div>
        </div>
      </div>
    </div>

    <div class="section section-2">
      <div class="content">
        <div class="text">
          <div class="text-1 color-orange">如何成为我们的合作伙伴</div>
          <div class="text-2 color-black">申请条件</div>
          <div class="text-3 color-black">
            企业性质的单位，拥有行业经验者优先
            <br />
            拥有系统推广团队，有固定驻点
            <br />
            有一定的渠道或客户资源
          </div>
          <div class="text-4 color-black">您将获得</div>
          <div class="text-5 color-black">
            | 培训支持 | 服务支持 | 市场支持 | 售前售后支持 |
          </div>
          <div>
            <a class="apply" href="https://www.yunlankeji.com/" target="_blank"
              >立即申请 / 登录</a
            >
          </div>
          <div class="text-6 color-black">
            招商热线
            <span class="color-orange">400-066-0526</span>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-3">
      <div class="content">
        <div class="text">
          <div class="text-1 color-orange">店教授合作伙伴</div>
        </div>
        <div class="imagelazyBox textc pt60 pb60">
          <el-image
            v-for="url in imgurls"
            :key="url"
            :src="url"
            class="imageItem"
          ></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/css/common/common.css";
export default {
  data() {
    return {
      imgurls: [
        require("../../assets/images/index/Frame_1.png"),
        require("../../assets/images/index/Frame_2.png"),
        require("../../assets/images/index/Frame_3.png"),
        require("../../assets/images/index/Frame_4.png"),
        require("../../assets/images/index/Frame_5.png"),
        require("../../assets/images/index/Frame_6.png"),
        require("../../assets/images/index/Frame_7.png"),
        require("../../assets/images/index/Frame_8.png"),
        require("../../assets/images/index/Frame_9.png"),
        require("../../assets/images/index/Frame_10.png"),
        require("../../assets/images/index/Frame_11.png"),
        require("../../assets/images/index/Frame_12.png"),
        require("../../assets/images/index/Frame_13.png"),
        require("../../assets/images/index/Frame_14.png"),
        require("../../assets/images/index/Frame_15.png"),
        require("../../assets/images/index/Frame_16.png"),
        require("../../assets/images/index/Frame_17.png"),
        require("../../assets/images/index/Frame_18.png"),
        require("../../assets/images/index/Frame_19.png"),
        require("../../assets/images/index/Frame_20.png"),
        require("../../assets/images/index/Frame_21.png"),
        require("../../assets/images/index/Frame_22.png"),
        require("../../assets/images/index/Frame_23.png"),
        require("../../assets/images/index/Frame_24.png"),
        require("../../assets/images/index/Frame_25.png"),
        require("../../assets/images/index/Frame_26.png"),
        require("../../assets/images/index/Frame_27.png"),
        require("../../assets/images/index/Frame_28.png"),
        require("../../assets/images/index/Frame_29.png"),
        require("../../assets/images/index/Frame_30.png"),
        require("../../assets/images/index/Frame_31.png"),
        require("../../assets/images/index/Frame_32.png"),
        require("../../assets/images/index/Frame_33.png"),
        require("../../assets/images/index/Frame_34.png"),
        require("../../assets/images/index/Frame_35.png"),
        require("../../assets/images/index/Frame_36.png"),
        require("../../assets/images/index/Frame_37.png"),
        require("../../assets/images/index/Frame_38.png"),
        require("../../assets/images/index/Frame_39.png"),
        require("../../assets/images/index/Frame_40.png"),
        require("../../assets/images/index/Frame_41.png"),
        require("../../assets/images/index/Frame_42.png"),
        require("../../assets/images/index/Frame_43.png"),
        require("../../assets/images/index/Frame_44.png"),
        require("../../assets/images/index/Frame_45.png"),
        require("../../assets/images/index/Frame_46.png"),
        require("../../assets/images/index/Frame_47.png"),
        require("../../assets/images/index/Frame_48.png"),
      ],
    }
  },
};
</script>

<style scoped>
.section-1 {
  height: 735px;
  background-image: url('../../assets/images/cooperation/section_1.jpg');
}
.section-1 .content .text {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 220px;
  text-align: center;
}
.section-1 .content .text .text-1 {
  font-size: 62px;
}
.section-1 .content .text .text-2 {
  font-size: 30px;
  margin: 30px 0 0 0;
}
.section-1 .content .text .text-3 {
  font-size: 18px;
  margin: 20px 0 0 0;
}
.section-1 .content .text .apply {
  font-size: 20px;
  width: 245px;
  height: 68px;
  line-height: 68px;
  color: #ff6727;
  text-align: center;
  display: block;
  margin: 80px auto 0 auto;
  border: 1px solid #ff6727;
  border-radius: 2px;
  z-index: 10;
}
.section-2 {
  height: 1219px;
  background-image: url('../../assets/images/cooperation/section_2.jpg');
}
.section-2 .content .text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 120px;
}
.section-2 .content .text .text-1 {
  font-size: 62px;
}
.section-2 .content .text .text-2 {
  font-size: 30px;
  margin: 360px 0 0 0;
}
.section-2 .content .text .text-3 {
  font-size: 24px;
  margin: 30px 0 0 0;
  line-height: 40px;
}
.section-2 .content .text .text-4 {
  font-size: 30px;
  margin: 70px 0 0 0;
}
.section-2 .content .text .text-5 {
  font-size: 24px;
  margin: 30px 0 0 0;
}
.section-2 .content .text .apply {
  font-size: 20px;
  width: 245px;
  height: 68px;
  line-height: 68px;
  color: #fff;
  text-align: center;
  display: block;
  margin: 80px auto 0 auto;
  background-color: #ff6727;
  border-radius: 2px;
  z-index: 10;
}
.section-2 .content .text .text-6 {
  font-size: 18px;
  margin: 30px 0 0 0;
}
.section-3 {
  height: 840px;
  /* background-image: url(/main/resource/image/partner/section/section_3.jpg); */
}
.section-3 .content .text {
  /* position: absolute; */
  width: 100%;
  text-align: center;
  margin-top: 100px;
  /* top: 130px; */
}
.section-3 .content .text .text-1 {
  font-size: 62px;
}
.imagelazyBox .imageItem:hover {
  transform: translateY(-6px) scale(1.1);
}
</style>